import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ManagementTeam from "../components/managementTeam"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      photo1: file(relativePath: { eq: "Wall_construction.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            src
          }
        }
      }
      photo2: file(relativePath: { eq: "US_Green_Building_Council_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            src
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="About Us" />
      <Helmet
        bodyAttributes={{
          class: "page-about-us",
        }}
      />
      <Row>
        <Col sm={12}>
          <h1>About Us</h1>
        </Col>
        <Col md={9}>
          <h3>
            At Capital Structures, we take pride in our customers' projects.
          </h3>
          <img
            className="img-fluid float-right ml-3 mb-3"
            src={data.photo1.childImageSharp.fluid.src}
            alt="Interior Construction"
          />
          <p>
            Our customers benefit from over 45 years of combined design
            experience in the manufactured component industry. We take great
            care to understand the needs of each customer individually. Whether
            purchasing roof trusses, floor trusses, engineered lumber products,
            wall panels, or any combination thereof, our customers can rest
            assured that they are receiving the most efficient and cost
            effective component system, and that it is specifically designed to
            suit their construction project.
          </p>
          <h3>We have a team of knowledgeable field representatives.</h3>
          <p>
            And every one of them is ready to ensure that the construction
            process remains positive, not only through design, but also through
            the jobsite construction phase. Our field technicians maintain the
            cleanest, most well-constructed jobsites in our industry. Our goal
            is to reduce site construction time for our customer while
            increasing structural integrity and the effective use of materials.
          </p>
          <h3>
            We follow green building standards to incorporate environmental
            considerations into every phase of the building process.
          </h3>
          <p>
            Using trusses and other engineered woods help a building to be
            genuinely sustainable. A buildings structure may or may not be seen
            once the construction process is done, but the choices made during
            construction have an impact. Conservation of wood through the use of
            intelligently designed smaller dimensional lumber is a great way to
            help conserve our forests.
          </p>
          <img
            className="float-right ml-3 mb-3"
            src={data.photo2.childImageSharp.fluid.src}
            alt="Interior Construction"
          />
          <p>
            Capital Structures component wood trusses, joists and wall units do
            not use large old-growth trees. Our custom shell frame designs
            significantly reduce lumber waste during fabrication and on the
            job-site. We are a LEEDS (Leadership in Energy and Environmental
            Design) supplier.
          </p>
          <h3>Let us add you to our list of satisfied customers today.</h3>
          <p>
            Contact us about receiving a quote on any of the services we
            provide. We design and manufacture roof trusses, floor trusses and
            wall panels and we offer installation services for all of our
            manufactured products. We also offer the "Shell Frame Construction"
            package, which includes all of the rough carpentry materials and
            labor for your project bundled into one easy to manage package.
          </p>
          <Button href="/contact" variant="primary">
            Get Started Today
          </Button>
          <hr />
          <ManagementTeam />
        </Col>
        <Col md={3}>
          <h3>Our History</h3>
          <p>
            In 1987, The Spradlins purchased Capital Structures. At that time it
            was a bankrupt Fort Smith Truss Company. After spending two years
            developing the business, it burned to the ground. It was then when
            we decided we were going to be "The Best You Can Get." We were
            raised to believe that it does not matter what you do, as long as
            you are "The Best."
          </p>
          <p>
            Everyone at Capital Structures is constantly reminding themselves of
            our commitment to respond to customer and market demands in order to
            keep Capital Structures and our customers on the cutting edge of
            product development and innovation. Our commitment to our customers
            makes Capital Structures the industry leader in the central United
            States.
          </p>
        </Col>
      </Row>
    </Layout>
  )
}

export default AboutUs
